import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { StaticImage } from "gatsby-plugin-image"

import { Hidden, Box, Typography, Container, Grid, Paper } from '@material-ui/core';

import Aboutback from "../components/aboutbg" // Tell Webpack this JS file uses this image
import Homebg from "../images/homebg.jpg"

import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

  const Homeback = {
    backgroundAttachment: 'fixed',
    backgroundImage: 'url('+ Homebg+')'
    }


const Expertise = () => (
  <Layout>
    <Seo title="Corporate Social Responsibilities" description="AriCorp Healthcare is strongly committed to its corporate social responsibility through activities related to promotion of better healthcare infrastructure, supporting the local community and support to local Government." />
    <ThemeProvider theme={theme}>
    <Aboutback title="Corporate Social Responsibilities" />

   <div style={Homeback}>
        <div style={{ backgroundColor:'rgba(236, 236, 236, 0.68)' }}>
   <Container>
     <Box pt={4} pb={5}>
     <Grid container alignItems="center" justify="center">
     <Grid item xs={12} sm={6} md={5}>
         <Box pr={3} pb={3}>
           <Paper elevation={3}>
           <StaticImage
      src="../images/csrimg.jpg"
      alt="Africorp Expertise"
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
    />
         </Paper>
         </Box>
         </Grid>

       <Grid item xs={12} sm={12} md={7} align="justify">
       <Typography gutterBottom paragraph variant="body1">
       AfriCorp Healthcare is strongly committed to its corporate social responsibility through activities related to promotion of better healthcare infrastructure, supporting the local community and support to local Government.
         </Typography>
         <Typography gutterBottom paragraph variant="body1">
         As a company we acknowledge our responsibility to the environment and to our local communities within which we work and co-operate. Thus, CSR constitutes an integral part of AriCorp Healthcare strategy. We actively encourage our staff to embrace our philosophy and behave in a responsible manner towards the society.
        </Typography>
        <Typography gutterBottom paragraph variant="body1">
         In addition, an important part of our CSR strategy is our employees. It is a vital objective for us to ensure their safety within the organization and provide equal opportunities.
        </Typography>
        <Typography gutterBottom paragraph variant="body1">
         We provide equal opportunities for both genders by seeking, recruiting, training and developing people. We persistently explore opportunities for professional improvement of our employees by encouraging and funding their participation in in-house and other open educational programs and expertise international training programs.
        </Typography>
        <Typography gutterBottom paragraph variant="body1">
        Developing Local talent. We are committed to developing local skilled workforce and integrating local professionals within our teams. 
        </Typography>
        <Typography gutterBottom paragraph variant="body1">
       We support local hospitals in the regions that we work in by carrying out training programs, periodic free equipment care  and other assisting in medical supplies.<b style={{ color:'blue' }}> We also seek out projects that we can involve, to improve the standards of healthcare and education within the communities in which we work.</b>
      </Typography>
       
       </Grid>

     </Grid>
     </Box>
   </Container>
   </div>
   </div>

   <Hidden mdUp>
    <Box mb={4}></Box>
    </Hidden>

    </ThemeProvider>
  </Layout>
)

export default Expertise
